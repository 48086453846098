
    import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
    import { Route, RawLocation } from "vue-router";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import ReservationPayload from "@/store/modules/reservation/models";
    import RoutesEnum from "@/router/routesEnum";
    import BookingInfo from "@/app/reservation/components/BookingInfo.vue";
    import PopUpCancelPayment from "@/app/reservation/components/PopUpCancelPayment.vue";
    import ReservationTokenModel from "@/models/reservationTokenModel";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import PopUpAgreement from "@/app/reservation/components/PopUpAgreement.vue";
    import { ReservationModel, ReservationPaymentModel, ReservationProductCategoryModel } from "@/apiManager/reservation/reservationData";
    import { SettingModel } from "@/apiManager/setting/settingData";
    import { PaymentRedirectReservationQuery, PaymentURLModel } from "@/apiManager/payment/paymentData";
    import { PaymentStatusEnum } from "@/apiManager/_common/enums";
    import { LoginTypeEnum } from "@/apiManager/login/loginData";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";

    //#region validateQuery
    let validateQuery = (p_Query: { [key: string]: string | (string | null)[] }): boolean =>
    {
        if (!p_Query.code)
        {
            return false;
        }
        if (p_Query.code.length === 0)
        {
            return false;
        }

        return true;
    };
    //#endregion

    @Component
    export default class Payment extends Mixins(BeforeRouteEnterMixin)
    {
        get components()
        {
            let components =
                {
                    "booking-info": BookingInfo,
                    "pop-up-cancel-payment": PopUpCancelPayment,
                    "format-money": FormatMoney,
                    "agreement" : PopUpAgreement
                };

            return components;
        }

        private reservCode: string = "";
        private amount: number = 0;
        private msgError: string = "";
        private showCancelPaymentModal: boolean = false;
        private canceledPayment: ReservationPaymentModel = new ReservationPaymentModel();
        private isPaymentDepositSelected: boolean = false;
        private openAgreement: boolean = false;
        private AgreementChecked: boolean = true;
        private AgreementNotCheckedError: boolean = false;

        @Watch("isPaymentDepositSelected")
        onWatchIsPaymentDepositSelected(value: boolean)
        {
            ReservationTokenModel.setIsPaymentDepositSelected(value);

            this.amount = this.getPaymentDue;
        }

        get amountEdit(): number
        {
            //@ts-ignore
            return parseFloat(this.amount).toFixed(2);
        }
        set amountEdit(value: number)
        {
            let valueFiltred: number = parseFloat(value.toFixed(2));
            if (isNaN(valueFiltred))
            {
                this.amount = 0;
            }
            else
            {
                this.amount = valueFiltred;
            }
        }

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getIsPaymentVisible(): boolean
        {
            return !((this.$tStore.state.configState.setting.license.disablePaymentAgent) && (this.$tStore.state.authenticationModal.authType === LoginTypeEnum.Agent));
        }

        get getReservationDetail(): ReservationModel
        {
            return this.$tStore.state.reservationState.detail;
        }

        get getPaymentReceivedList(): ReservationPaymentModel[]
        {
            return this.$tStore.state.reservationState.detail.listPayment;
        }

        get getPaymentReceivedTotal(): number
        {
            let totalPayment = this.getPaymentReceivedList.reduce((total: number, payment) =>
            {
                if (payment.status === PaymentStatusEnum.Active)
                {
                    return total + payment.amount;
                }
                return total;
            }, 0);

            return totalPayment;
        }

        get getDeposit(): number
        {
            return this.getReservationDetail.deposit;
        }

        get getPaymentTotal(): number
        {
            return this.getReservationDetail.total;
        }

        @Watch("getPaymentDue")
        onWatchGetPaymentTotal(value: number)
        {
            this.amount = this.getPaymentDue;
        }

        get getPaymentDue(): number
        {
            let paymentDue: number = 0;
            if (this.isPaymentDepositSelected)
            {
                paymentDue = this.getReservationDetail.deposit - this.getPaymentReceivedTotal;
            }
            else
            {
                paymentDue = this.getReservationDetail.total - this.getPaymentReceivedTotal;
            }

            return Math.round(paymentDue * 100) / 100;
        }

        get getDurationLabel(): string
        {
            if (this.getReservationDetail.mainProduct.durationInNights)
            {
                return (this.getReservationDetail.mainProduct.duration - 1) + " " + this.i18n(this.getRezToursLanguageEnum.nights);
            }
            else
            {
                return this.getReservationDetail.mainProduct.duration + " " + this.i18n(this.getRezToursLanguageEnum.days);
            }
        }

        get getBusPickupLabel(): string
        {
            if ((this.getReservationDetail.busPickup !== null) && (this.getReservationDetail.busPickup !== undefined))
            {
                return `${ this.getReservationDetail.busPickup.time } - ${ this.getReservationDetail.busPickup.name }`;
            }
            else
            {
                return "";
            }
        }

        get getProductCategoryList(): ReservationProductCategoryModel[]
        {
            let listCateogry: ReservationProductCategoryModel[] = [];
            listCateogry.push(this.getReservationDetail.mainProduct.categoryAdult);
            if (this.getReservationDetail.mainProduct.categoryChild.quantity > 0)
            {
                listCateogry.push(this.getReservationDetail.mainProduct.categoryChild);
            }
            return listCateogry;
        }

        beforeRouteEnter(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (validateQuery(p_To.query))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        beforeRouteUpdate(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (validateQuery(p_To.query))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        beforeMount()
        {
            this.reservCode = this.$route.query.code as string || "";
        }

        mounted()
        {
            this.amountEdit = this.getReservationDetail.total - this.getPaymentReceivedTotal;
            this.isPaymentDepositSelected = ReservationTokenModel.isPaymentDepositSelected();
            this.AgreementChecked = (this.getPaymentReceivedList.length > 0) || (!this.getIsPaymentVisible);
        }

        onCancelClick()
        {
            this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
        }

        isAddPaymentValid()
        {
            if (this.getReservationDetail.withTerms && !this.AgreementChecked)
            {
                this.AgreementNotCheckedError = true;
                return false;
            }
            else
            {
                this.AgreementNotCheckedError = false;
            }

            if (isNaN(this.amountEdit))
            {
                this.msgError = this.i18n(this.getRezToursLanguageEnum.paymentIsNumber);
                return false;
            }

            if (this.amountEdit <= 0)
            {
                this.msgError = this.i18n(this.getRezToursLanguageEnum.paymentNotNegative);
                return false;
            }

            this.msgError = "";
            return true;
        }

        async onAddPaymentClick()
        {
            if (this.isAddPaymentValid())
            {
                let token: string = new ReservationTokenModel().raw;
                this.$tStore.commit(new ReservationPayload.Mutations.SetRedirect());

                try
                {
                    let query: PaymentRedirectReservationQuery = new PaymentRedirectReservationQuery();
                    query.reservation = this.reservCode;
                    query.amount = this.amountEdit;
                    query.isDeposit = this.isPaymentDepositSelected;
                    query.token = token;
                    query.lang = this.g_Language;

                    let paymentURL: PaymentURLModel = await this.$apiManager.payment.getURLReservationAsync(query);
                    window.location.replace(paymentURL.url);
                }
                catch
                {

                }
            }
        }

        isCreateInvoiceValid()
        {
            if (this.getReservationDetail.withTerms && !this.AgreementChecked)
            {
                this.AgreementNotCheckedError = true;
                return false;
            }
            else
            {
                this.AgreementNotCheckedError = false;
            }

            return true;
        }

        async onCreateInvoiceClick()
        {
            if (this.isCreateInvoiceValid())
            {
                let payload = new ReservationPayload.Actions.CreateInvoice(this.g_Language, true);
                try
                {
                    await this.$tStore.dispatch(payload);
                }
                catch
                {
                    ReservationTokenModel.delete();
                    this.$tStore.dispatch(new ReservationPayload.Actions.DeleteReservation(this.reservCode));

                    let payloadModal = new ModalInformativePayload.Mutations.DisplayModalInformative(
                        this.i18n(this.getRezToursLanguageEnum.serverErrorTitle),
                        this.i18n(this.getRezToursLanguageEnum.serverErrorMsg)
                    );
                    this.$tStore.commit(payloadModal);

                    return this.$router.replace(
                        {
                            name: RoutesEnum.Package
                        }
                    );
                }

                ReservationTokenModel.delete();
                this.$tStore.commit(new ReservationPayload.Mutations.SetReservationDetail(new ReservationModel()));
                return this.$router.replace(
                    {
                        name: RoutesEnum.Booking
                    }
                );
            }
        }

        onCancelPaymentClick(payment: ReservationPaymentModel)
        {
            this.canceledPayment = payment;
            this.showCancelPaymentModal = true;
        }

        onClickBack()
        {
            this.$router.replace({
                name: RoutesEnum.PaxInfo,
                query:
                    {
                        code: this.$tStore.state.reservationState.detail.code
                    }
            });
        }

        onAgreementClick()
        {
            this.openAgreement = true;
        }
    }
